import $ from 'jquery';

$(document).on('click', '#popup-new-item-submit', function(){
    const newItem = $("#new_item_string").val();
    const id = $('#item_row_id').data('item_row_id');
    $.ajax({
      url: "/item.json",
      type: "POST",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {item: {name: newItem}},
      success: function(res){
        console.log(res);
        if (res.errors) {
          res.errors.forEach((message) => {
            $('#item-errors-list')
            .append($('<div/>')
            .html(message)
            .addClass('alert alert-danger'))
          })
        } else {
          $('#item-close').trigger('click');
          $("#new_item_string").val('');
          var option = {
            id: res.data.id,
            name: res.data.name,
          }
          var newOption = new Option(option.name, option.id, true, true);
          $("#"+id+"_row .dynamic-append-options").append(newOption).trigger('change');
        }
      },
      error: function(e){
        console.log(e);
      }
    });
});

$(document).on('click', '#popup-edit-customer-submit', function (){
  var form = $("#cusomer-form-id");
  var data = {}
  $.each(form.serializeArray(), function(i, field) {
      data[field.name] = field.value;
  });
  $.ajax({
    url: "/customers",
    type: "PUT",
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data,
    success: function(res){
        console.log(res);
        if (res.errors) {
          res.errors.forEach((message) => {
            $('#cusomer-errors-list')
            .append($('<div/>')
            .html(message)
            .addClass('alert alert-danger'))
          })
        } else {
          $('#customer-close').trigger('click');
          var option = {
            id: res.data.id,
            name: res.data.name + ' ' + res.data.company_name,
          }
          var newOption = new Option(option.name, option.id, true, true);
          $('#customer_select_2').append(newOption).trigger('change');
        }
    },
    error: function(){
      console.log("AJAX call unsuccesful");
    }
  });
})

$(document).on('click', '.assigned-to-close-icon', function(){
    $('.assigned-to-icons-div').css('display','none');
});

$(document).on('click', '.lead-status-style .bootstrap-close-icon', function(){
    var data = $('.lead-status-style').data('current_lead');
    $('.status-icons-div').css('display','none');
    $('#show-status-update').val(data);
});

$(document).on('click', '#popup-customer-submit', function(){
    var form = $("#cusomer-form-id");
    var data = {}
    $.each(form.serializeArray(), function(i, field) {
        data[field.name] = field.value;
    });
    $.ajax({
      url: "/customers.json",
      type: "POST",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: data,
      success: function(res){
          console.log(res);
          if (res.errors) {
            $('#cusomer-errors-list').html('');
            res.errors.forEach((message) => {
              $('#cusomer-errors-list')
              .append($('<div/>')
              .html(message)
              .addClass('alert alert-danger'))
            })
          } else {
            $('#customer-close').trigger('click');
            var option = {
              id: res.data.id,
              name: res.data.name + ' ' + res.data.company_name,
            }
            var newOption = new Option(option.name, option.id, true, true);
            $('#customer_select_2').append(newOption).trigger('change');
          }
      },
      error: function(){
        console.log("AJAX call unsuccesful");
      }
    });
});

$(document).on('change', '#show-status-update', function(){
    $('.status-icons-div').css('display','flex');
});

$(document).on('change', '#assigned-to-update', function(){
    $('.assigned-to-icons-div').css('display','flex');
});

$(document).on('click', '.lead-status-style .bootstrap-check-icon', function(){
    var state_data = $('#show-status-update').val();
    var lead_id = $('#lead-id').data("lead_id");
    $.ajax({
      url: "/order/status/update",
      type: "POST",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {id: lead_id, status: state_data},
      success: function(data){
          $('#show-status-area').text(state_data);
          $('.status-icons-div').css('display','none');
      },
      error: function(){
        console.log("AJAX call unsuccesful");
      }
    });
});

$(document).on('click', '#calendar-icon-toggle', function(){
    $('#calendar-icon-toggle').css('display','none');
    $('#date-picker-flow').css('display','block');
});

$(document).on('click', '#add-item-row', function () {
    // fetch n where n is the number of record used to append
    const itemList = $("#item-metadata").data('company-items');
    let id = $("#item-metadata").data('new-record-id');
    const leadItemsCount = $("#item-metadata").data('lead-items-count');
    id = leadItemsCount > 1 && id <= leadItemsCount ? leadItemsCount + 1 : id
    const htmlRowElement = '<div class="row" id="nth_row"><div class="col-lg-6"><div class=""><div class="flexer"><div class="margin-right-auto lead-dull">Item</div><div class="padding-left-1 text-primary add-new-position set-item" data-set-item-record=nth data-bs-toggle="modal" data-bs-target="#itemFormModal">Add New +</div></div></div><select class="form-control lead-form-input-style dynamic-append-options" required="required" name="lead[item][nth][id]"><option value="" label=" "></option></select></div><div class="col-lg-6"><div class="lead-form-input"><label class="lead-dull">Quantity</label><div class="flexer-space-between"><input class="form-control lead-form-input-style" type="text" required="required" name="lead[item][nth][quantity]"><div><img src="https://i.ibb.co/HVnDxm0/down-arrow.png" class="additional-fields-arrow cursor-pointer" data-id="nth"><img src="https://i.ibb.co/db7SGSF/delete.png" class="delete_items_record cursor-pointer" data-id="nth"></div></div></div></div></div>'.replaceAll('nth', id);
    $('#dynamic-rows').append(htmlRowElement);
    const additionalHtml = '<div id="additional-fields-nth" style="display: none;"><div class="sub-form-style"><h6>Additional Info</h6><div class="filter-flex"><div class="lead-form-input width-330 margin-right-10"><label class="lead-dull">Paper Type</label><input class="form-control" type="text" name="lead[item][nth][paper_type]"></div><div class="lead-form-input width-330 margin-right-10"><label class="lead-dull">Size</label><input class="form-control" type="text" name="lead[item][nth][size]"></div><div class="lead-form-input width-330 margin-right-10"><label class="lead-dull">Colour</label><input class="form-control" type="text" name="lead[item][nth][colour]"></div></div></div></div>'.replaceAll('nth', id);
    $('#dynamic-rows').append(additionalHtml);
    itemList.forEach(element => {
      let appendOption = `<option value=${element[0]}>${element[1]}</option>`;
      $("#"+id+"_row .dynamic-append-options").append(appendOption);
    });
    $("#item-metadata").data('new-record-id', id + 1);
});

$(document).on('click', '.delete_items_record', function () {
    const deleteId = "#" + $(this).data('id') + "_row";
    $(deleteId).remove();
});

$(document).on('click', '.additional-fields-arrow', function () {
  const additionalFieldId = "#additional-fields-" + $(this).data('id');
  if ($(this).attr('class').includes('rotated')) {
    $(this).removeClass('rotated');
  } else {
    $(this).addClass('rotated');
  }
  $(additionalFieldId).toggle();
});

$(document).on('click', '.set-item', function () {
  const itemRecordId = $(this).data('set-item-record');
  $('#item_row_id').data('item_row_id', itemRecordId);
});

$(document).on('click', '#order-form-submit', function () {
  const form = $('#order-form');
  const data = {};
  $.each(form.serializeArray(), function(i, field) {
    data[field.name] = field.value;
  });
  $.ajax({
    url: "/leads.json",
    type: "POST",
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data,
    success: function(res){
        console.log(res);
        if (res.errors) {
          $('#leads-errors-list').html('');
          res.errors.forEach((message) => {
            $('#cusomer-errors-list')
            .append($('<div/>')
            .html(message)
            .addClass('alert alert-danger'))
          })
        } else {
          window.location.href = res.data.redirection_link
        }
    },
    error: function(){
      console.log("AJAX call unsuccesful");
    }
  });
})

$(document).on('click', '#email-only-submit-form-button', function(e) {
  const form = $('#email-only-submit-form');
  const data = {};
  $.each(form.serializeArray(), function(i, field) {
    data[field.name] = field.value;
  });
  $.ajax({
    url: "/password/reset.json",
    type: "POST",
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data,
    success: function(res){
      $('#password-reset-message').html('');  
      if (res.errors) {
        $('#password-reset-message')
        .append($('<div/>')
        .html(res.errors)
        .addClass('alert alert-danger'));
      } else {
        $('#password-reset-message')
        .append($('<div/>')
        .html(res.data)
        .addClass('alert alert-success'));
      }
    },
    error: function(){
      console.log("AJAX call unsuccesful");
    }
  });
})

$(document).on('click', '#password-submit', function() {
  const form = $('#password-set-form');
  const data = {};
  $.each(form.serializeArray(), function(i, field) {
    data[field.name] = field.value;
  });
  $.ajax({
    url: "/employee/password.json",
    type: "PATCH",
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data,
    success: function(res){  
      if (res.errors) {
        $('#password-change-errors-list').html('');
        res.errors.forEach((message) => {
          $('#password-change-errors-list')
        .append($('<div/>')
        .html(message)
        .addClass('alert alert-danger'));
        })
      } else {
        $('#passwordChange .modal-body').html('Password Updated Successfully');
        $('#passwordChange .modal-footer').html('');
        $('#passwordChange .modal-body').addClass('text-success');
      }
    },
    error: function(){
      console.log("AJAX call unsuccesful");
    }
  });
})